<template>
  <div class="pay-reward-desc">
    <van-tabs v-model="tabIndex" background="#f5f5f5">
      <van-tab title="每日充值">
        <div class="content">
          <section class="_editor">
            <p>
              <span style="color: #ff0000;"
                ><strong
                  ><span style="font-size: 15px;"
                    >网站充值奖励自动发放，但是程序运行难免出错，如果在游戏系统内没有收到奖励邮件，请手动到网站领取！！！</span
                  ></strong
                ></span
              >
            </p>
            <p>
              <span style="color: #ff0000;"
                ><strong
                  ><span style="font-size: 15px; color: #ff0000;"
                    ><br /></span></strong
              ></span>
            </p>
            <p>
              <span style="color: #ff0000;"
                ><strong
                  ><span style="font-size: 15px; color: #ff0000;"
                    >每日充值奖励过了12点之后，将会重置，所以必须当天充值当天领完，否则一律无法找回！！！</span
                  ></strong
                ></span
              >
            </p>
            <p>
              <span style="color: #ff0000;"
                ><strong
                  ><span style="font-size: 15px; color: #ff0000;"
                    ><br /></span></strong
              ></span>
            </p>
            <p>
              <span style="font-size: 15px; color: #ff0000;"
                ><strong
                  >举例：假如一天内充值了400元，即可包含每日30元、100元、200元、400元的所有奖励，并非只有400元的奖励！</strong
                ></span
              >
            </p>
            <p>
              <strong
                ><span style="font-size: 15px;"><br /></span
              ></strong>
            </p>

            <van-image
              src="/jrlj.jpg"
              style="width: 100%; min-height: 200px;"
            ></van-image>
          </section>
        </div>
      </van-tab>
      <van-tab title="累计充值">
        <div class="content">
          <section class="_editor">
            <p>
              <span style="color: #ff0000;"
                ><strong
                  ><span style="font-size: 15px;"
                    >网站充值奖励自动发放，但是程序运行难免出错，如果在游戏系统内没有收到奖励邮件，请手动到网站领取！！！</span
                  ></strong
                ></span
              >
            </p>
            <p>
              <span style="color: #ff0000;"
                ><strong
                  ><span style="font-size: 15px; color: #ff0000;"
                    ><br /></span></strong
              ></span>
            </p>
            <p>
              <span style="font-size: 15px; color: #ff0000;"
                ><strong
                  >举例：假如总共充值了1000元，即可包含累计150元、累计500元、累计1000元的所有奖励，并非只有1000元的奖励！</strong
                ></span
              >
            </p>
            <p>
              <strong
                ><span style="font-size: 15px;"><br /></span
              ></strong>
            </p>
            <van-image
              src="/lj.jpg"
              style="width: 100%; min-height: 200px;"
            ></van-image>
            <section class="_editor">
              <p>
                <br />
              </p>
            </section>
          </section>
        </div>
      </van-tab>

      <van-tab title="充值攻略">
        <div class="content">请咨询群主</div>
      </van-tab>
    </van-tabs>
    <div class="to-pay">
      <van-button block round type="danger" @click="toGetPayReward"
        >前往领取奖励</van-button
      >
    </div>
  </div>
</template>
<script>
import { Tabs, Tab, Button, Image } from "vant";
import { mapState } from "vuex";

export default {
  name: "PayRewardDesc",
  components: {
    "van-tabs": Tabs,
    "van-tab": Tab,
    "van-button": Button,
    "van-image": Image
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  computed: {
    ...mapState({
      setting: state => state.setting.setting
    })
  },
  methods: {
    toGetPayReward() {
      window.location.href = `${this.setting.client3BaseUrl}/#/reward`;
    }
  }
};
</script>
<style lang="less" scoped>
.content {
  padding: 20px 20px 100px 20px;
}

.to-pay {
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
}
</style>
